<template>
  <div>
    <div class="zhfwtop">
       <div class="indeximgtopcontent">
        <div class="indexlogo"></div>
        <div class="indexlist">
          <ul class="indexlistul">
            <li  @click="go('/home')"><span>首页</span></li>
            <li  class="indexli2">
              <span >产品解决方案</span>
                <ul class="menu">
                  <li  @click="go('/cpjjfa/zhfw')">智慧法务</li>
                  <span  @click="go('/cpjjfa/zhcg')">智慧采购</span>
                  <li  @click="go('/cpjjfa/zhrz')">智慧人资</li>
                  <li @click="go('/cpjjfa/zhsj')">智慧审计</li>
                  <li  @click="go('/cpjjfa/bgxt')">办公协同</li>
                  <li @click="go('/cpjjfa/dsj')">大数据分析-BI技术平台</li>
                </ul>
            </li>
            <li @click="go('/jszc')"><span>技术支持</span></li>
            <li @click="go('/yhal')"><span>用户案例</span></li>
            <li @click="go('/gywm')"><span class="active2">关于我们</span></li>
          </ul>
      </div>
      <a href="http://www.kingislucky.cn:81/#/home" class="enbutton">
        <img src="../.././assets/enicon.png" alt="">
        <div>Translate</div>
      </a>
    </div>

      <div class="zhfwbutton"></div>
    </div>
    <!--  -->
    <div class="jjfa">
      <div class="jjfalisttou">
        <div class="jjfalist">
          <div class="jjfalistevery ">
              <span  class="jjfalisteverytitle jjfalistevery_span">企业介绍</span>
            </div>
            <div class="jjfalistevery">
              <span class="jjfalisteverytitle">股东介绍</span>
            </div>
            <div class="jjfalistevery">
              <span class="jjfalisteverytitle">企业文化</span>
            </div>
            <div class="jjfalistevery">
              <span class="jjfalisteverytitle">企业新闻</span>
            </div>
            <div class="jjfalistevery">
              <span class="jjfalisteverytitle">加入我们</span>
            </div>

        </div>
      </div>

      <div class="jjfacontent">

        <div class="box">
        <ul>
            <li class="current">
              <div class="jjfatitle1">
                <div class="hxystitleback"></div>
                <div class="hxystitl-ti">公司介绍</div>
                <div class="hxystitleback2"></div>
              </div>
              <div class="jjfalisteveryindurce"></div>
              <div class="gsjsinduce">
                <div class="ABOUTUS"><img src="../.././assets/ABOUTUS.png" alt=""></div>
                <div class="gsjsinduceleft"><img src="../.././assets/gsjspng.png" alt=""></div>
                <div class="gsjsinduceright">

                  中科星图瑞云科技有限公司成立于2017年，是国家高新技术企业，由中国科学技术大学计算机专业校友领军创办，位于安徽省合肥市经济技术开发区高校三创园，主要从事数据治理、数据应用开发，以及人工智能机器人研发。
公司核心技术成员均接受国内外顶尖学校的系统教育，具有国际视野和扎实的理论基础。他们跟踪IT前沿技术，专注于大数据、人工智能的研发和产业化落地，积累了丰富的研发项目经验。
企业已获得国家高新技术企业、合肥市首批人工智能企业、双软认定企业、ISO9001认证等荣誉资质。
公司拥有发明专利两项、实用新型专利两项、软件著作权13个。
                </div>
              </div>
              <div class="gsjspng"><img src="../.././assets/gsjspng2.png" alt=""></div>
            </li>
            <li>
              <div class="jjfatitle100">
                <div class="hxystitleback"></div>
                <div class="hxystitl-ti">股东介绍</div>
                <div class="hxystitleback2"></div>
              </div>
              <div class="gudong">
                <div class="gudongback"><img src="../.././assets/zhongkxt.png" alt=""></div>
              </div>
              <div class="gudonginduce">
                         中科星图股份有限公司（股票代码：688568）创立于 2006 年，2020 年 7 月在科创板上市，是中国科学院空天信息创新研究院投资的国有控股高新技术企业。 作为国内数字地球产品研发与产业化的先行者和领军企业，中科星图将 大数据、云计算和人工智能等新一代信息技术、地理信息技术与航空航天 产业深度融合，自主研发了覆盖空天大数据获取、处理、承载、可视化和应用等产业链环节的 GEOVIS 数字地球产品，面向政府、企业、特种及大众领域用户提供软件销售和数据服务、技术开发服务、数字地球一体机和系统集成，促进了我国数字地球的产业化发展。
       “十四五”时期，公司制定了集团化、生态化、国际化发展战略，形成特种领域、智慧政府、气象生态、航天测运控、企业能源、线上业务六大业务板块，建立以北京、西安、青岛、合肥四个管理总部为核心，39 家参控股分子公司为主体，全国 20 多个办事处为补充的集团化架构，进一步实现公司组织结构和管理模式战略转型，保持了经营业绩平稳增长。 中科星图积极探索北斗高分融合的产品型态和应用模式，构建了线下产 品和线上产品共创共建、融合发展的产品体系。线下产品方面，形成了 “1+1+1+N”的产品体系架构，实现面向特种领域、智慧政府、气象生态、 航天测运控、企业能源等板块的“GEOVIS+”行业应用生态；线上产品方面， 通过研制全新的 GEOVIS Earth 星图地球系列产品，实现数字地球产品的国产化替代，为全球用户提供优质的数字地球在线服务和体验。
       坚持创新驱动发展，以构建可计算的数字地球为目标，中科星图持续加强核心技术研究，打造了一支高水平的创新团队，建成数字地球院士专家工作站和博士后科研工作站，获得专利和软件著作权 1211 项，并先后承担国家高分重大专项、科技部国家重点研发计划、北京市重大专项。随着新一代信息技术和地理信息技术的发展，GEOVIS 数字地球的技术、产品与系统形成多主体、多层次、多行业的应用格局，成为面向“一带一路”“乡村振兴”“数字经济”“碳达峰、碳中和”等国家重大需求的重要新兴软件平台。
       中科星图是中国地理信息产业协会副会长单位、中国气象服务协会副会 长单位、中国软件行业协会副理事长单位、中国指挥与控制学会副理事长单位、中国遥感应用协会常务理事单位、中国卫星导航定位协会常务理事单位。公司获得全国工人先锋号、国家规划布局内重点软件企业、高新技术企业、北京市企业技术中心、北京市高精尖产业技术中心、北京市国民经济和装备动员重点单位、北京市科学技术奖一等奖、部级科技进步一、 二、三等奖、2022 年地理信息产业百强企业、地理信息科技创新贡献单位、 中国证券报“金牛科创奖”、地理信息产业高成长 TOP50 企业、中国最具影响力软件和信息服务企业、十大创新软件企业和十大软件创新产品、2020 年中国科学院科技促进发展奖、中国测绘学会“2021 年测绘科学技术奖” 特等奖、中国地理信息产业协会“2022 地理信息科技进步一等奖”、中国指挥与控制学会“2021 年科学技术进步奖一等奖”、中国气象服务协会“2020 年气象技术发明奖”一等奖、工信部 2020 年大数据产业发展试点示范项目名单等多项荣誉。
       仰望星空，脚踏实地。中科星图将以先行者的担当，在核心技术、产业 融合、商业模式等方面加强前沿布局，营造开放包容、创新引领、合作共赢的生态氛围，致力于成为全球领先的空天大数据系统与服务提供商。
              </div>
            </li>
            <li>
              <div class="jjfatitle2">
                <div class="hxystitleback"></div>
                <div class="hxystitl-ti">公司文化</div>
                <div class="hxystitleback2"></div>
              </div>
              <div class="jjfalisteveryindurce"></div>
              <div class="gswh">
                <div class="gswhleft">
                  <div class="gswhlefttop"></div>
                  <div class="gswhleftbottom">
                    <div class="gswhleftbottom1">使命</div>
                    <div class="gswhleftbottom2">竭尽全力创造客户认可的有价值的产品和服务</div>
                  </div>
                </div>
                <div class="gswhleft2">
                  <div class="gswhleftbottom">
                    <div class="gswhleftbottom1">寄语</div>
                    <div class="gswhleftbottom2">坚定信念，齐心协力，积跬汇涓，共建辉煌</div>
                  </div>
                  <div class="gswhlefttop"></div>

                </div>
                <div class="gswhleft3">
                  <div class="gswhlefttop"></div>
                  <div class="gswhleftbottom">
                    <div class="gswhleftbottom1">价值观</div>
                    <div class="gswhleftbottom2">客户、务实、创新、团队、担当、激情</div>
                  </div>
                </div>

              </div>
            </li>
            <li>
              <div class="jjfatitle3">
                <div class="hxystitleback"></div>
                <div class="hxystitl-ti">公司新闻</div>
                <div class="hxystitleback2"></div>
              </div>
              <div class="jjfalisteveryindurce"></div>
              <div v-for="items in 1" :key="items" class="gsxwbottom">
                <div class="gsxwbottomleft"><img src="../.././assets/nianhuiback.png" alt=""></div>
                <div class="gsxwbottomright">
                  <div class="gsxwbottomrighttitle">【赢战2022】—中科星图瑞云科技有限公司2021年终工作总结暨年会盛典圆满落幕！</div>
                  <div class="gsxwbottomrighttitme">2022-04-27</div>
                  <div class="gsxwbottomrightinduce">同唱和谐新乐章，共绘未来好宏图。</div>
                  <div class="gsxwbottomrightinduce2">
                    <div>2022年2月19日，我公司的年会如期而至，全体员工欢聚一堂，细数一年中大家风雨同舟，奋进...</div>
                    <div class="getmore">
                      <div class='more' @click="moreto">更多</div>
                      <div class="icon"><i class="el-icon-right"></i></div>
                    </div>
                  </div>
                </div>

              </div>
              <!-- <img class="homebanner3" src="../.././assets/fwzc_banner3.png" alt=""> -->
            </li>
            <li>
              <div class="jjfatitle4">
                <div class="hxystitleback"></div>
                <div class="hxystitl-ti">加入我们</div>
                <div class="hxystitleback2"></div>
              </div>
              <div class="jjfalisteveryindurce"></div>
              <div class="jrwmtop">
                <div class="jrwmtopevery">
                  <img src="../.././assets/jrwmtoppng1.png" alt="">
                  <div >五险一金</div>
                </div>
                <div class="jrwmtopevery">
                  <img src="../.././assets/jrwmtoppng2.png" alt="">
                  <div >双休</div>
                </div>
                <div class="jrwmtopevery">
                  <img src="../.././assets/jrwmtoppng3.png" alt="">
                  <div >节日礼金</div>
                </div>
                <div class="jrwmtopevery">
                  <img src="../.././assets/jrwmtoppng4.png" alt="">
                  <div >带薪年假</div>
                </div>
                <div class="jrwmtopevery">
                  <img src="../.././assets/jrwmtoppng5.png" alt="">
                  <div >项目激励</div>
                </div>
              </div>
              <div class="jrwmbottom">
                <div class="jrwmbottomevery">
                  <div class="jrwmbottomeveryleft">
                    <div class="jrwmbottomeverytitle">产品经理</div>
                    <div class="jrwmbottomeveryinduce">研发岗位  合肥/蜀山区</div>
                  </div>
                  <div @click="toboss"  class="jrwmbottomeveryright">加入我们</div>
                </div>
                <div class="jrwmbottomevery">
                  <div class="jrwmbottomeveryleft">
                    <div class="jrwmbottomeverytitle">C++研发工程师</div>
                    <div class="jrwmbottomeveryinduce">研发岗位  合肥/蜀山区</div>
                  </div>
                  <div @click="toboss"  class="jrwmbottomeveryright">加入我们</div>
                </div>
                <div class="jrwmbottomevery">
                  <div class="jrwmbottomeveryleft">
                    <div class="jrwmbottomeverytitle">UI设计</div>
                    <div class="jrwmbottomeveryinduce">研发岗位  合肥/蜀山区</div>
                  </div>
                  <div @click="toboss"  class="jrwmbottomeveryright">加入我们</div>
                </div>
                <div class="jrwmbottomevery">
                  <div class="jrwmbottomeveryleft">
                    <div class="jrwmbottomeverytitle">前端开发工程师</div>
                    <div class="jrwmbottomeveryinduce">研发岗位  合肥/蜀山区</div>
                  </div>
                  <div  @click="toboss" class="jrwmbottomeveryright">加入我们</div>
                </div>
              </div>
              <div class="jrwmposition" v-if="show">
                  <div class="jjfatitleend">
                    <div class="hxystitleback"></div>
                    <div class="hxystitl-ti">联系我们</div>
                    <div class="hxystitleback2"></div>
                  </div>
                  <div class="jrwmpositionbottom">
                    <div id="jrwmpositionbottommap" class="jrwmpositionbottommap">
                      <div class="gogaode" @click="pushgaode()"><img src="../.././assets/daohang.png" alt=""></div>
                    </div>
                    <div class="jrwmpositionbottomright">
                      <div class="jrwmpositionbottomright1">中科星图瑞云科技有限公司</div>
                      <div class="jrwmpositionbottomrigh2">地址：安徽省滁州市南谯区高教科创城科创产业园1号科研楼101室</div>
                      <div class="jrwmpositionbottomrigh3">邮编：230022</div>
                      <div class="jrwmpositionbottomright4">电话：0551-65650717</div>
                    </div>
                  </div>
              </div>
            </li>

        </ul>

    </div>
      </div>
    </div>
    <tabar-bottom></tabar-bottom>
  </div>
</template>

<script>
import TabarBottom from '../../components/TabarBottom.vue'
import AMapLoader from '@amap/amap-jsapi-loader';
  export default {
    components:{
       TabarBottom
    },
    data(){
       return{
        show:false,
        map:null,
        index:0,
        activeIndex: '1',
      }
    },
    created(){

    },
    mounted(){
    this.loadMap()
      var uli = document.querySelectorAll('.box ul li')
      var oli = document.querySelectorAll('.jjfalistevery span')
       for (let i = 0; i < oli.length; i++) {
        oli[i].index = i
        oli[i].onclick = ()=>{
            for (var j = 0; j < uli.length; j++) {
                uli[j].className = ''
                oli[j].className = ''
            }
            this.index = i
            console.log(this.index)
            if(this.index==4){
              this.show=true
              this.loadMap()
            }else{
              this.show=false
            }
            oli[this.index].className = 'jjfalistevery_span'
            uli[this.index].className = 'current'
        }
    }

    },
    computed:{

    },
    methods:{

      toboss(){
         window.open('https://www.zhipin.com/gongsir/0707a4a597580fed1Xd73di1Eg~~_100000.html?ka=position-2', '_blank')
      },
      moreto(){
         let routerData = this.$router.resolve({
            path: '/gywm/more'
          })
          window.open(routerData.href, '_blank')
      },
      pushgaode(){
        window.open('https://www.amap.com/search?query=%E6%BB%81%E5%B7%9E%E9%AB%98%E6%95%99%E7%A7%91%E5%88%9B%E5%9F%8E&city=341100&geoobj=118.163075%7C32.159366%7C118.571785%7C32.341555&zoom=12.27')
      },
      loadMap(){
        this.initMap()
      },
       initMap(){
        AMapLoader.load({
            key:"90ef4bd704adab8e569f5ec063c4773e",             // 申请好的Web端开发者Key，首次调用 load 时必填
            version:"2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
            plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],       // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        }).then((AMap)=>{
            this.map = new AMap.Map("jrwmpositionbottommap",{  //设置地图容器id
                viewMode:"2D",    //是否为3D地图模式
                zoom:17,
                zoomEnable:false,   //是否缩放
                dragEnable: false,  //是否拖动         //初始化地图级别
                center:[117.3198 , 31.85071], //初始化地图中心点位置
            });
           var text = new AMap.Text({
                text:'中科星图瑞云科技有限公司',
                anchor:'center', // 设置文本标记锚点
                draggable:false,
                cursor:'pointer',
                // angle:10,
                style:{
                    'padding': '.75rem 1.25rem',
                    'margin-bottom': '1rem',
                    'border-radius': '.25rem',
                    'background-color': 'white',
                    'width': '288px',
                    "height":'16px',
                    'border-width': 0,
                    'box-shadow': '0 2px 6px 0 rgba(114, 124, 245, .5)',
                    'text-align': 'center',
                    'font-size': '20px',
                    'color': '#53555B',
                    'background-image': "url('../.././assets/导航.png')"
                },
                position: [117.3199 , 31.85091]
            });

    text.setMap(this.map);
            var marker = new AMap.Marker({
            position: new AMap.LngLat(117.31991 , 31.85071),
            offset: new AMap.Pixel(-10, -10),
            icon: require('../.././assets/mark.png'), // 添加 Icon 图标 URL
            // title: '北京'
        });
        //   marker.setLabel({
        //     direction:'top',
        //     offset: new AMap.Pixel(10, 0),  //设置文本标注偏移量
        //     content: "<div class='info'>安徽中科星图瑞云科技有限公司 </div><img src=('../.././assets/daohang.png')>", //设置文本标注内容
        // });
        this.map.add(marker);
        }).catch(e=>{
            // console.log(e);
        })

    },
      go(path){
        this.$router.push(
          {
            path:path
          }
        )
      },
      toright(){
         var uli = document.querySelectorAll('.box ul li')
         var oli = document.querySelectorAll('.jjfalistevery span')
         this.index++
         console.log(this.index,uli.length)
        if (this.index == uli.length) {
            this.index = 0
        }
        for (var i = 0; i < uli.length; i++) {
            uli[i].className = ''
            oli[i].className = ''
        }
        uli[this.index].className = 'current'
        oli[this.index].className = 'jjfalistevery_span'
      },
      toleft(){
        var uli = document.querySelectorAll('.box ul li')
        var oli = document.querySelectorAll('.jjfalistevery span')
         this.index--
        if (this.index < 0) {
            this.index = uli.length - 1
        }
        for (var i = 0; i < uli.length; i++) {
            uli[i].className = ''
            oli[i].className = ''
        }
        uli[this.index].className = 'current'
        oli[this.index].className = 'jjfalistevery_span'
      }
    },
  }
</script>


<style lang="scss" scoped>
.enbutton{
  cursor: pointer;
  position: absolute;
  z-index: 9999;
  right: 5%;
  top: 42px;
  font-size: 14px;
  font-family: Arial-Regular, Arial;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  width: 102px;
  height: 28px;
  align-items: center;
  justify-content: space-around;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #FFFFFF;
}
.gudong{
  text-align: center;
}
.gudonginduce{
  width: 1200px;
height: 772px;
font-size: 20px;
font-family: Source Han Sans CN-Regular, Source Han Sans CN;
font-weight: 400;
color: #282A2E;
  text-indent: 2rem;
}
.ABOUTUS{
  position: absolute;
  top: 0;
  margin-left: 36%;
  z-index: -1;
}
.indeximgtopcontent{
   width: 1223px;
  height: 46px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  // border: 1px solid red;
}
.gsxwbottom{
  width: 1200px;
  display: flex;
  height: 260px;
  align-items: center;
  border-bottom: 1px solid #EFEFEF;
  .getmore{
    width: 60px;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
  }
  .gsxwbottomleft{
    width: 300px;
    height: 160px;
    margin-right: 18px;

  }
  .gsxwbottomright{
    .gsxwbottomrighttitle{
        width: 583px;
        height: 20px;
        font-size: 20px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #282A2E;
    }
    .gsxwbottomrighttitme{
      margin-top: 42px;
      margin-bottom: 36px;
      width: 583px;
      height: 15px;
      font-size: 16px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #858B95;
    }
    .gsxwbottomrightinduce,.gsxwbottomrightinduce2{
      width: 686px;
      font-size: 16px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #282A2E;
    }
    .gsxwbottomrightinduce2{
      width: 848px;
      display: flex;
      margin-top: 14px;
      justify-content: space-between;
    }
  }
}
.gogaode{
  position: absolute;
  top: 125px;
  left: 422px;
  z-index: 3;

}
.jrwmposition{
  width: 100%;
  height: 582px;
  background: url('../.././assets/jrwmpositionback.png') no-repeat center center;
  .jrwmpositionbottom{
    width: 1200px;
    height: 300px;
    display: flex;
  }
  .jrwmpositionbottommap{
    width: 578px;
    height: 336px;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
  }
  .jrwmpositionbottomright{

    padding-left: 20px;
    padding-top: 36px;
    width: 622px;
    height: 300px;
    background: #fff;
    .jrwmpositionbottomright1{
      width: 370px;
      height: 35px;
      font-size: 28px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #282A2E;
      line-height: 24px;
    }
    .jrwmpositionbottomrigh2{
      width: 486px;
      height: 50px;
      font-size: 20px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #282A2E;
      line-height: 30px;
      margin-top: 30px;
    }
    .jrwmpositionbottomrigh3{
      margin-top: 30px;
      width: 171px;
      height: 27px;
      font-size: 20px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #282A2E;
      line-height: 24px;
    }
    .jrwmpositionbottomright4{
      width: 351px;
      margin-top: 30px;
      height: 27px;
      font-size: 20px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #282A2E;
      line-height: 24px;
    }
  }
}
.jrwmbottom{
  margin-top: 74px;
  width: 1200px;
  height: 400px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .jrwmbottomevery{
    width: 580px;
    height: 180px;
    background: url('../.././assets/jrwmbottomeveryback.png') no-repeat center center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding-left: 40px;
    // padding-right: 40px;
    .jrwmbottomeveryleft{
      height: 74px;
      width: 157px;
      display: flex;
      flex-direction: column;

      margin-left: 40px;
      .jrwmbottomeverytitle{
        font-size: 20px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #282A2E;
      }
      .jrwmbottomeveryinduce{
        font-size: 16px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #848079;
        margin-top: 14px;
      }
    }
    .jrwmbottomeveryright{
      margin-right: 40px;
      font-size: 16px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 140px;
      height: 40px;
      background: #FFA000;
      border-radius: 4px 4px 4px 4px;
    }
  }
}
.jrwmtop{
  width: 1200px;
  height: 140px;
  display: flex;
  box-shadow: 4px 4px 10px 1px rgba(0, 0, 0, 0.16);
  .jrwmtopevery{
    img{
      width: 45px;
      height: 58px;
    }
    div{
      margin-top: 14px;
      font-size: 20px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #282A2E;
    }
    background: #FFFFFF;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 20%;
    height: inherit;
  }
}
.gswh{
  width: 1200px;
  height: 520px;

  display: flex;
  margin-bottom: 88px;
  .gswhleft{
    width: 400px;
    height: inherit;
    .gswhlefttop{
      width: 400px;
      height: 260px;
      background: url('../.././assets/gswhpng1.png') no-repeat center center;
    }
    .gswhleftbottom{

      height: 50%;
      text-align: center;
      .gswhleftbottom1{
        float: left;
        margin-left: 50%;
        transform: translateX(-24px);
        margin-top: 79px;
        border-bottom: 1px solid #FFA000;
        width: 48px;
        height: 34px;
        line-height: 24px;
        font-size: 24px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #282A2E;
      }
      .gswhleftbottom2{
        float: left;
        width: 320px;
        margin-left: 50%;
        transform: translateX(-160px);
        margin-top: 50px;
        height: 16px;
        font-size: 16px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #848079;
      }
    }
  }
  .gswhleft2{
    width: 400px;
    height: inherit;
    .gswhlefttop{
      width: 400px;
      height: 260px;
      background: url('../.././assets/gswhpng2.png') no-repeat center center;
    }
    .gswhleftbottom{

      height: 50%;
      text-align: center;
      .gswhleftbottom1{
        float: left;
        margin-left: 50%;
        transform: translateX(-24px);
        margin-top: 79px;
        border-bottom: 1px solid #FFA000;
        width: 72px;
        height: 34px;
        line-height: 24px;
        font-size: 24px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #282A2E;
      }
      .gswhleftbottom2{
        float: left;
        width: 320px;
        margin-left: 50%;
        transform: translateX(-160px);
        margin-top: 50px;
        height: 16px;
        font-size: 16px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #848079;
      }
    }
  }
  .gswhleft3{
    width: 400px;
    height: inherit;
    .gswhlefttop{
      width: 400px;
      height: 260px;
      background: url('../.././assets/gswhpng3.png') no-repeat center center;
    }
    .gswhleftbottom{

      height: 50%;
      text-align: center;
      .gswhleftbottom1{
        float: left;
        margin-left: 50%;
        transform: translateX(-24px);
        margin-top: 79px;
        border-bottom: 1px solid #FFA000;
        width: 72px;
        height: 34px;
        line-height: 24px;
        font-size: 24px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #282A2E;
      }
      .gswhleftbottom2{
        float: left;
        width: 320px;
        margin-left: 50%;
        transform: translateX(-160px);
        margin-top: 50px;
        height: 16px;
        font-size: 16px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #848079;
      }
    }
  }
}
.gsjspng{
  height: 260px;
  margin-top: 80px;
  margin-bottom: 77px;
}
.gsjsinduce{
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .gsjsinduceright{
    text-indent: 2rem;
   width: 586px;
  height: 230px;
  font-size: 20px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #282A2E;
    // display: flex;
    // align-items: flex-end;
  }
}
.jjfalisttou{
  width: 100%;
  margin: 0 auto;
  height: 80px;
  background: #F8F8F8;
  line-height: 80px;
}
.active{
  display: inline-block;
  color: #FFA000;
  line-height: 30px;
  border-bottom: 1px solid #FFA000;
}
.menu{
  position: absolute;
  display: none;
  top: 45px;
}
.menu li:hover{
  color: #FFA000;
}
.indexli2{
  position: relative;
}
.indexli2:hover .menu{
  display: block;
}
// 合作伙伴
.hzhb{

  background: #F8F8F8;
  // width: 1903px;
  height: 884px;
  margin: 0 auto;
  .hzhbcontent{
    padding-top: 80px;
      width: 1200px;
      height: inherit;
      margin: 0 auto;
      .hzhbtitle{
  width: 450px;
  line-height: 33px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 65px;
  display: flex;
  justify-content: space-between;
  .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('../.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('../.././assets/righticon.png') no-repeat center center;
  }
}
  }

}
//
.yyfaimglie1,.yyfaimglie2,.yyfaimglie3{
  width: 32%;
  height: 100%;

}
// 行业应用方案
.yyfaimg1:hover .yyfaimg1mask{
          display: block;
        }
.yyfaimg1{

        width: 386px;
        height: 240px;

        position: relative;
        .img{
          position: absolute;
          left: 0;
          top: 0;
        }

        .yyfaimg1title{
          color: #fff;
          background: #332716;
          opacity: 0.8;
          text-align: center;
          width: 386px;
          line-height: 60px;
          position: absolute;
          bottom: 0;
        }
        .yyfaimg1mask{
          display: none;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: #FFA000;
          opacity: 0.9;
        }
      }
      //
      .yyfaimg2:hover .yyfaimg2mask{
          display: block;
        }
.yyfaimg2{
        width: 386px;
        height: 280px;

        position: relative;
        .img{
          position: absolute;
          left: 0;
          top: 0;
        }

        .yyfaimg2title{
          color: #fff;
          background: #332716;
          opacity: 0.8;
          text-align: center;
          width: 386px;
          line-height: 60px;
          position: absolute;
          bottom: 0;
        }
        .yyfaimg2mask{
          display: none;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: #FFA000;
          opacity: 0.9;
        }
      }
      //
         .yyfaimg3:hover .yyfaimg3mask{
          display: block;
        }
.yyfaimg3{
        width: 386px;
        height: 320px;

        position: relative;
        .img{
          position: absolute;
          left: 0;
          top: 0;
        }

        .yyfaimg3title{
          color: #fff;
          background: #332716;
          opacity: 0.8;
          text-align: center;
          width: 386px;
          line-height: 60px;
          position: absolute;
          bottom: 0;
        }
        .yyfaimg3mask{
          display: none;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: #FFA000;
          opacity: 0.9;
        }
      }
      //
      .yyfaimg4:hover .yyfaimg4mask{
          display: block;
        }
.yyfaimg4{
  margin-top: 10px;
        width: 386px;
        height: 340px;

        position: relative;
        .img{
          position: absolute;
          left: 0;
          top: 0;
        }

        .yyfaimg4title{
          color: #fff;
          background: #332716;
          opacity: 0.8;
          text-align: center;
          width: 386px;
          line-height: 60px;
          position: absolute;
          bottom: 0;
        }
        .yyfaimg4mask{
          display: none;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: #FFA000;
          opacity: 0.9;
        }
      }
      //
      .yyfaimg5:hover .yyfaimg5mask{
          display: block;
        }
.yyfaimg5{
  margin-top: 10px;
        width: 386px;
        height: 240px;

        position: relative;
        .img{
          position: absolute;
          left: 0;
          top: 0;
        }

        .yyfaimg5title{
          color: #fff;
          background: #332716;
          opacity: 0.8;
          text-align: center;
          width: 386px;
          line-height: 60px;
          position: absolute;
          bottom: 0;
        }
        .yyfaimg5mask{

          display: none;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: #FFA000;
          opacity: 0.9;
          .yyfaimg5masktitle{
            width: 48px;
            margin-left: 50%;
            transform: translateX(-24px);
            line-height: 50px;
            font-size: 24px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
            color: #FFFFFF;
          }
          .yyfaimg5maskcontent{
            margin-left: 50%;
            transform: translateX(-140px);
            width: 280px;
            height: 54px;
            font-size: 20px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
            color: #FFFFFF;
          }
        }
      }
      //
      .yyfaimg6:hover .yyfaimg6mask{
          display: block;
        }
.yyfaimg6{
  margin-top: 7px;
        width: 386px;
        height: 520px;

        position: relative;
        .img{
          position: absolute;
          left: 0;
          top: 0;
        }

        .yyfaimg6title{
          color: #fff;
          background: #332716;
          opacity: 0.8;
          text-align: center;
          width: 386px;
          line-height: 60px;
          position: absolute;
          bottom: 0;
        }
        .yyfaimg6mask{
          display: none;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: #FFA000;
          opacity: 0.9;
        }
      }
       .yyfaimg7:hover .yyfaimg7mask{
          display: block;
        }
.yyfaimg7{
  margin-top: 19px;
        width: 386px;
        height: 240px;

        position: relative;
        .img{
          position: absolute;
          left: 0;
          top: 0;
        }

        .yyfaimg7title{
          color: #fff;
          background: #332716;
          opacity: 0.8;
          text-align: center;
          width: 386px;
          line-height: 60px;
          position: absolute;
          bottom: 0;
        }
        .yyfaimg7mask{
          display: none;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: #FFA000;
          opacity: 0.9;
        }
      }
      //
       .yyfaimg8:hover .yyfaimg8mask{
          display: block;
        }
.yyfaimg8{
  margin-top: 16px;
        width: 386px;
        height: 302px;

        position: relative;
        .img{
          position: absolute;
          left: 0;
          top: 0;
        }

        .yyfaimg8title{
          color: #fff;
          background: #332716;
          opacity: 0.8;
          text-align: center;
          width: 386px;
          line-height: 60px;
          position: absolute;
          bottom: 0;
        }
        .yyfaimg8mask{
          display: none;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: #FFA000;
          opacity: 0.9;
        }
      }
.yyfa{
  margin: 0 auto;
  // width: 1903px;
  height: 1153px;
  background: #fff;
  .yyfacontent{
    width: 1200px;
    height: inherit;
    margin: 0 auto;

    .yyfaimg{
       display: flex;
    flex-flow: column wrap;
      width: 1200px;
      height: 850px;

      margin: 0 auto;

    }
  }
}
.yyfatitle{
  width: 500px;
  line-height: 33px;
  margin: 0 auto;
  margin-top: 77px;
  margin-bottom: 88px;
  display: flex;
  justify-content: space-between;
  .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('../.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('../.././assets/righticon.png') no-repeat center center;
  }
}
//解决方案
.jjfalist{
  width: 1200px;
  height: 80px;

 margin: 0 auto;
  .jjfalistevery{
    cursor: pointer;
    text-align: center;
    width: 20%;
    float: left;
    height: inherit;
    span{
      font-size: 16px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      display: inline-block;
      line-height: 80px;
    }
    span:hover{
      font-size: 16px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #FFA000;
      display: inline-block;
      line-height: 80px;
      border-bottom: 2px solid #FFA000;
    }
  }
}
.indeximg{
  // width: 1903px;
  height: 800px;
  margin: 0 auto;
  background: url('../.././assets/homebackground.png') no-repeat center center;
  background-size: 1918px 800px;
}
.indexlogo{
  // width: 220px;
  // height: 46px;
  // background: url('../.././assets/logo.png') center center;
}


.hxys{
  // width: 1903px;
  height: 834px;

  margin: 0 auto;
}
.hxyscontent{
  width: 1200px;
  height: inherit;
  margin: 0 auto;

}
.hxystitle{
  width: 450px;
  line-height: 33px;
  margin: 0 auto;
  margin-top: 85px;
  margin-bottom: 65px;
  display: flex;
  justify-content: space-between;
  .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('../.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('../.././assets/righticon.png') no-repeat center center;
  }
}
.hxysbottomevery{
  margin-bottom: 86px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .hxysbottomeveryright{
    width: 962px;
    p{
      line-height: 53px;
      font-size: 24px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #282A2E;
    }
    div{
      font-size: 16px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #848079;
    }
  }
  }
.jjfa{
    // width: 1903px;
    // padding-top: 20px;
    // height: 812px;
    margin: 0 auto;
    position: relative;
    // background: url('../.././assets/cpjjfabackground.png') no-repeat center;
    // background-size: 1903px 812px;
  }
  .jjfacontent{

    width: 1200px;
    // height: 747px;
    margin: 0 auto;

  }
 .jjfatitle1{
  width: 430px;
  line-height: 33px;
  margin: 80px auto 90px auto;
  display: flex;
  justify-content: space-between;

  .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('../.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('../.././assets/righticon.png') no-repeat center center;
  }
}
.jjfatitle2{
  width: 413px;
  line-height: 33px;
  margin: 0 auto;
  margin-top: 77px;
  margin-bottom: 60px;
  display: flex;
  justify-content: space-between;

  .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('../.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('../.././assets/righticon.png') no-repeat center center;
  }
}
.jjfatitleend{
  width: 404px;
  line-height: 33px;
  margin: 0 auto;
  padding-top: 87px;
  margin-bottom: 60px;
  display: flex;
  justify-content: space-between;

  .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('../.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('../.././assets/righticon.png') no-repeat center center;
  }
}
.jjfatitle3{
  width: 410px;
  line-height: 33px;
  margin: 0 auto;
  margin-top: 80px;
  margin-bottom: 60px;
  display: flex;
  justify-content: space-between;

  .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('../.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('../.././assets/righticon.png') no-repeat center center;
  }
}
.jjfatitle100{
   width: 519px;
  line-height: 33px;
  margin: 0 auto;
  margin-top: 77px;
  margin-bottom: 60px;
  display: flex;
  justify-content: space-between;
   .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('../.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('../.././assets/righticon.png') no-repeat center center;
  }
}
.jjfatitle4{
  width: 519px;
  line-height: 33px;
  margin: 0 auto;
  margin-top: 77px;
  margin-bottom: 60px;
  display: flex;
  justify-content: space-between;

  .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('../.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('../.././assets/righticon.png') no-repeat center center;
  }
}
.jjfatitle5{
  width: 587px;
  line-height: 33px;
  margin: 0 auto;
  margin-top: 77px;
  margin-bottom: 60px;
  display: flex;
  justify-content: space-between;

  .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('../.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('../.././assets/righticon.png') no-repeat center center;
  }
}
// 轮播
.jjfalisteveryindurce{
  font-size: 18px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #282A2E;
  text-indent: 2rem;
}
//
  .box {
    margin-top: 30px;
            width: 1200px;
            // height: 600px;

            // overflow: hidden;
            cursor: pointer;
        }

        .box ul,
        .box ol {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        .box ul li {
            width: 1200px;
            // height: 600px;
            display: none;
        }

        .box ul li  {
          .homebanner1{
            margin-top: 20px;
            // margin-left: 50%;
            // transform: translate(-600px);
            width: 1200px;
            height: 970px;
            margin-bottom: 88px;
          }
          .homebanner2{
            margin-top: 20px;
            // margin-left: 50%;
            // transform: translate(-600px);
            width: 1200px;
            height: 522px;
            margin-bottom: 88px;
          }
          .homebanner3{
            margin-top: 20px;
            // margin-left: 50%;
            // transform: translate(-600px);
            width: 1200px;
            height: 988px;
            margin-bottom: 88px;
          }
          .homebanner4{
            margin-top: 30px;
            // margin-left: 50%;
            // transform: translate(-600px);
            width: 1200px;
            height: 856px;
            margin-bottom: 88px;
          }
          .homebanner5{
            margin-top: 30px;
            // margin-left: 50%;
            // transform: translate(-600px);
            width: 1200px;
            height: 1076px;
            margin-bottom: 88px;
          }

        }

        .box ol {
            width: 120px;
            position: absolute;
            left: 50%;
            margin-left: -40px;
            bottom: 20px;
            height: 20px;
            background: #fff;
            display: flex;
            justify-content: space-evenly;
            padding: 10px 0;
            border-radius: 20px;
        }

        .box ol li {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: #f00;
            border: 1px solid #999;
        }

        .box ol li.current {
            background: #00f;
        }

        .box ul li.current {
            display: block;
        }

        .box .left{
          position: absolute;
            top: 50%;
            left: 100px;
            width: 60px;
            height: 60px;
            margin-top: -15px;
            text-align: center;
            line-height: 30px;
            text-decoration: none;
            font-weight: bold;
        }
        .box .right {
           position: absolute;
            top: 50%;
            right: 100px;
            width: 60px;
            height: 60px;
            margin-top: -15px;
            text-align: center;
            line-height: 30px;
            text-decoration: none;
            font-weight: bold;
        }

        .left {
            left: 0;
        }

        .right {
            right: 0;
        }
        .jjfalistevery_span{
        color: #FFA000 !important;
         border-bottom:2px solid #FFA000 !important;
}
//
.active{
  display: inline-block;
  color: #FFA000;
  line-height: 30px;
}
.active2{
  display: inline-block;
  color: #FFA000;
  line-height: 30px;
  border-bottom: 1px solid #FFA000;
}
.zhfwtop{
  // width: 1903px;
  height: 466px;
  background: url('../.././assets/gywmindexback.png') no-repeat center 0;
  margin: 0 auto;
  padding-top: 34px;
  .indexlogo{
  width: 220px;
  height: 52px;
  background: url('../.././assets/logo.png') no-repeat center center;
}
.zhfwbutton{
  float: left;
  width: 373px;
  height: 175px;
  margin-left: 774px;
  margin-top: 90px;
}
.zhfwindc{
  text-align: center;
  float: left;
  width: 1100px;
  height: 51px;
  font-size: 16px;
  margin-top: 20px;
  margin-left: 432px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
}
.indexlist{
  width: 632px;
  font-size: 14px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  .indexlistul{
    display: flex;
    text-align: center;
    li{
      cursor: pointer;
      width: 160px;
      line-height: 45px;
      span:hover{
        cursor: pointer;
        text-align: center;
        color: #FFA000;
      }
    }

  }
}
//
.menu{
  position: absolute;
  display: none;
  top: 45px;
  background: #fff;
  color: #282A2E;
  padding-left: 20px;
  text-align: left;
  left: 16px;
}
.menu li:hover{
  color: #FFA000;
}
.indexli2{
  position: relative;
}
.indexli2:hover .menu{
  display: block;
}
}
</style>
